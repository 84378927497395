import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import GroupTextAnimation from "./GroupTextAnimation";
import { getPartnersList } from "../../../../reducers/homeSlice";
import { PartnerLogo } from "./PartnerLogo/PartnerLogo";
import { GroupCarouselAnimation, WrapperGroupCarousel } from "./GroupStyle";
import ListLogoPartner from "./PartnerLogo2/ListLogoPartner";

import "./group.scss";

export default function Group() {
  const dispatch = useDispatch();

  const [inView, setInView] = useState(false);

  // get data from homeSlice
  const partners = useSelector((state) => state.homeSlice.partners);

  useEffect(() => {
    // call api from homeSlice
    dispatch(getPartnersList());
  }, []);

  const [offset, setOffset] = useState(0);
  const handleScroll = () => setOffset(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="bg-partnersContactBg overflow-hidden ">
        <div
          id="aboutUs"
          className=" md:pb-0 lg:pb-0 xl:pb-[0px]  sm:mt-[130px] xl:mt-[230px] mt-[10px]"
        >
          <div className="mx-[34px] md:mx-[117px] 2xl:mx-[300px] 2xl:pb-[0px]  pt-[70px]">
            <div
              id="aboutUsText"
              className="w-auto h-[500.5px]  sm:w-[939px] sm:h-[370px] xl:w-[1030px] xl:h-[498px]  "
            >
              {/* start animation */}
              <div inView={inView}>
                <InView onChange={setInView} triggerOnce>
                  {({ ref }) => (
                    <div ref={ref}>
                      {inView ? <GroupTextAnimation /> : <></>}
                    </div>
                  )}
                </InView>
              </div>

              {/* end animation */}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-partnersContactBg ">
        <ListLogoPartner listPartners={partners} />
      </div>
    </div>
  );
}
