import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailservices } from "../../../../services/detailService";
import "./banner.scss";

const Banner = () => {
  const bannerData = useSelector(
    (state) => state.companyDetailSlice.detailList
  );

  // const founderData = bannerData?.acf?.founders.split(",");

  var tags = [];

  const [datatags, setDatatags] = useState([]);

  useEffect(() => {
    if (bannerData && Object.values(bannerData?.acf).length > 0) {
      Object.values(bannerData?.acf?.tags)?.map((item) => {
        tags.push(item);
      });
    }
    setDatatags([...tags]);
  }, [bannerData]);
  return (
    <div id="detailpage-banner">
      <div className="banner">
        <div className="banner-title">
          <div className="airkitchen">
            <p className="title-text">{bannerData?.acf?.company_name}</p>
            <div id="tags" className="flex flex-wrap gap-x-[12px] gap-y-[10px]  mt-10">
              {datatags
                ?.filter((item) => {
                  return item.length > 0;
                })
                .map((tag, i) => {
                  return (
                    <div
                    id="div-tag"
                      className="bg-[#DB2F33] rounded-[4px] w-fit inline-block"
                      key={i}
                    >
                      <p id="tag-p" className="text-white font-normal italic text-lg robotoFlex py-[6px] px-[12px]">
                        {tag}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="banner-logo">
            <div id="logo-cpn" className="rounded-full bg-[#f8f8f8] flex items-center justify-center">
            {!bannerData?.acf?.logo ? (
                <></>
              ) : (
                <img
                  src={bannerData?.acf?.logo}
                  alt="logo"
                  className="w-[190px] h-[190px]"
                />
              )}
            </div>
          </div>
        </div>
        <div className="banner-line">
          <hr />
        </div>

        <div className="banner-line-mobile">
          <hr />
        </div>

        <div className="banner-table">
          <div className="column-item-1">
            <p className="column-item-name">Established</p>
            <p className="column-item-value">{bannerData?.acf?.established}</p>
          </div>
          <div className="column-item-2">
            <p className="column-item-name">Founder</p>
            
              <p className="column-item-value inline">{bannerData?.acf?.founders}</p>
              
            
          </div>
          <div className="column-item-3">
            <p className="column-item-name">Region</p>
            <p className="column-item-value">{bannerData?.acf?.region}</p>
          </div>
        </div>

        <div className="banner-content">
          <p>{bannerData?.acf?.description}</p>
        </div>
      </div>

      <div className="body-line">
        <hr />
      </div>
    </div>
  );
};

export default Banner;
