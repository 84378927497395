import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./investment.scss";

const Investment = () => {
  const getData = useSelector((state) => state.companyDetailSlice.detailList);
  // console.log("getData :", getData);

  const inv = getData?.acf?.investments;
  // console.log('inv', inv)
  

  const [viewMore, setViewMore] = useState(false);

  var data = [];
  const [dataInves, setDataInves] = useState([]);

  const [sortDataInves, setSortDataInves] = useState(null);
  const limitData = sortDataInves?.slice(0, 3);

  useEffect(() => {
    if (getData && Object.values(getData?.acf).length > 0) {
      Object.values(getData?.acf?.investments).map((item) => {
        if (item.year !== "") {
          data.push(item);
        }
      });
    }
    setDataInves([...data]);
  }, [getData]);


  const dectectFirstLast = (index) => {
    if (index === 0) {
      return "firstBorderCol";
    } else if (index === dataInves.length - 1) {
      return "lastBorderCol";
    } else {
      return "borderCol";
    }
  };

  //sort date
  useEffect(() => {
    const sortedData = dataInves.sort(
      (objA, objB) => Number(objB.year) - Number(objA.year)
    );
    setSortDataInves(sortedData);
  }, [dataInves]);


  return (
    <div id="detailpage-investment">
      <div className="investment">
        <div className="investment-title">
          <h2 className="inv-text" id="inv">
            Investment<span className="dot">.</span>
          </h2>
        </div>
        <div className=" mt-[40px] lg:mt-[80px]">
          {/* desktop */}
          <div className="hidden lg:block">
            <table>
              <tr>
                <th>
                  <p className="pr-10 text-left font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                    Year
                  </p>
                </th>
                <th>
                  <p className="px-10 text-left font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                    Round
                  </p>
                </th>
                <th>
                  <p className="pl-10 text-left font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                    Investors
                  </p>
                </th>
              </tr>
              <br />
              
              {sortDataInves?.length <= 1
                ? sortDataInves?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td id="td-year">
                          <p className="pr-10 font-normal font-popins text-2xl text-[#101010]">
                            {item.year}
                          </p>
                        </td>
                        <td id="td-round" className="oneItemBorderCol relative">
                          <div className="max-w-[415px]">
                            <p
                              className={
                                item.round.length <= 25
                                  ? "px-10 font-normal font-popins text-2xl text-[#101010] w-max border-black"
                                  : "px-10 font-normal font-popins text-2xl text-[#101010] border-black"
                              }
                            >
                              {item.round}
                            </p>
                          </div>
                        </td>
                        <td id="td-investor" className="oneItemBorderCol relative">
                          {
                              item.investor ?
                              <p className="pl-10 font-light font-robotoFlex text-lg text-[#404040]"
                              dangerouslySetInnerHTML={
                                {
                                  __html: 
                                              item.investor.match(/Contrau\sVentures/g)
                                              ?
                                                item.investor.replace(
                                                  /Contrau\sVentures/g,
                                                  `<p class="font-robotoFlex text-lg text-[#DB2F33] italic font-extrabold inline">
                                                      ${item.investor.match(/Contrau\sVentures/g)[0]|| ""}</p>`
                                                )
                                              : item.investor
                                            
                                    
                                }
                              }
                              >
                              </p>
                              : ""
                            }
                        </td>
                      </tr>
                    );
                  })
                : sortDataInves?.map((item, index) => {
                    return (
                      <tr class="spaceUnder" key={index}>
                        <td id="td-year-2">
                          <p className="pr-10 font-normal font-popins text-2xl text-[#101010]">
                            {item.year}
                          </p>
                        </td>
                        <td id="td-round-2" className={dectectFirstLast(index)}>
                          <div className="max-w-[415px]">
                            <p
                              className={
                                item.round.length <= 25
                                  ? "px-10 font-normal font-popins text-2xl text-[#101010] w-max"
                                  : "px-10 font-normal font-popins text-2xl text-[#101010]"
                              }
                            >
                              {item.round}
                            </p>
                          </div>
                        </td>
                        <td id="td-investor-2" className={dectectFirstLast(index)}>
                          {
                              item.investor ?
                              <p className="pl-10 font-light font-robotoFlex text-lg text-[#404040]"
                              dangerouslySetInnerHTML={
                                {
                                  __html: 
                                    item.investor.match(/Contrau\sVentures/g)
                                    ?
                                      item.investor.replace(
                                        /Contrau\sVentures/g,
                                        `<p class="font-robotoFlex text-lg text-[#DB2F33] italic font-extrabold inline">
                                            ${item.investor.match(/Contrau\sVentures/g)[0]|| ""}</p>`
                                      )
                                    : item.investor
                                  
                                    
                                }
                              }
                              >
                              </p>
                              : ""
                            }
                        </td>
                      </tr>
                    );
                  })}

            </table>
          </div>

          {/* mobile */}
          <div className="block lg:hidden">
            {viewMore ? (
              <>
                {sortDataInves?.map((item, index) => {
                  return (
                    <div className="flex flex-wrap  border-l-[1px] border-black pl-6 mb-[40px]">
                      <div className="w-1/2 ">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Year
                        </p>
                        <p className="text-[13px] font-normal leading-[26px] font-popins text-[#101010]">
                          {item.year}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Round
                        </p>
                        <p className="text-[13px] font-normal leading-[26px] font-popins text-[#101010]">
                          {item.round}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Investors
                        </p>
                        {
                              item.investor ?
                              <p className="font-robotoFlex font-light text-[13px] text-[#101010]"
                              dangerouslySetInnerHTML={
                                {
                                  __html: 
                                    item.investor.match(/Contrau\sVentures/g)
                                    ?
                                      item.investor.replace(
                                        /Contrau\sVentures/g,
                                        `<p class="font-robotoFlex text-[13px] text-[#DB2F33] font-extrabold italic inline">
                                            ${item.investor.match(/Contrau\sVentures/g)[0]|| ""}</p>`
                                      )
                                    : item.investor
                                }
                              }
                              >
                              </p>
                              : ""
                            }
                      </div>
                    </div>
                  );
                })}
                <AnchorLink href="#inv" className="block lg:hidden">
                  <p
                    className="underline text-center text-black cursor-pointer "
                    onClick={() => {
                      setViewMore(!viewMore);
                    }}
                  >
                    View less
                  </p>
                </AnchorLink>
              </>
            ) : (
              <>
                {limitData?.map((item, index) => {
                  return (
                    <div className="flex flex-wrap  border-l-[1px] border-black pl-6 mb-[40px]">
                      <div className="w-1/2 ">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Year
                        </p>
                        <p className="text-[13px] font-normal leading-[26px] font-popins text-[#101010]">
                          {item.year}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Round
                        </p>
                        <p className="text-[13px] font-normal leading-[26px] font-popins text-[#101010]">
                          {item.round}
                        </p>
                      </div>
                      <div className="w-full mt-3">
                        <p className="font-extrabold font-popins text-sm italic leading-[24px] text-[#DB2F33]">
                          Investors
                        </p>
                        {
                              item.investor ?
                              <p className="font-robotoFlex font-light text-[13px] text-[#101010]"
                              dangerouslySetInnerHTML={
                                {
                                  __html: 
                                    item.investor.match(/Contrau\sVentures/g)
                                    ?
                                      item.investor.replace(
                                        /Contrau\sVentures/g,
                                        `<p class="font-robotoFlex text-[13px] text-[#DB2F33] font-extrabold italic inline">
                                            ${item.investor.match(/Contrau\sVentures/g)[0]|| ""}</p>`
                                      )
                                    : item.investor
                                }
                              }
                              >
                              </p>
                              : ""
                            }
                      </div>
                    </div>
                  );
                })}
                {limitData?.length < 4 ? (
                  <></>
                ) : (
                  <>
                    <p
                      className="underline text-center text-black  cursor-pointer block lg:hidden"
                      onClick={() => {
                        setViewMore(!viewMore);
                      }}
                    >
                      View more
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
