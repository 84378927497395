import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import dot1 from "../../../../../assets/interview-img/dot1.png";
import dot2 from "../../../../../assets/interview-img/dot2.png";
import { interviewServices } from "../../../../../services/interviewService";
import { useState } from "react";
import News from "./component/News/News";

InterviewBody.propTypes = {
  detailArticle: PropTypes.object,
};



function InterviewBody({ detailArticle }) {
  const theme = useTheme();

  return (
    <Box>
      {/* FRAME CONTENT */}
      <div className="framearticle">
        <div className="framehtmlinterview">
          <div className="framequote">
            <div className="framedot1">
              <img src={dot1} />
            </div>
            <div className="frametitleqoute">
              <div
                className="title1"
                dangerouslySetInnerHTML={{
                  __html: detailArticle?.qoute?.part_1 || "",
                }}
              />
            </div>
            <div className="framedot2">
              <img src={dot2} />
            </div>
          </div>

          {!detailArticle ? (
            <div />
          ) : (
            <div
              className="framearticle"
              dangerouslySetInnerHTML={{
                __html: detailArticle.content_part_1?.detail_content || "",
              }}
            />
          )}

          {!detailArticle ? (
            <div />
          ) : (
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html:
                  detailArticle.content_part_1?.detail_content_image || "",
              }}
            />
          )}
        </div>
        <div className="framearticle">
          {!detailArticle ? (
            <div />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: detailArticle.content_part_2?.detail_content || "",
              }}
            />
          )}
          {!detailArticle ? (
            <div />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: detailArticle.content_part_3?.detail_content || "",
              }}
            />
          )}
          {!detailArticle ? (
            <div />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: detailArticle.content_part_4?.detail_content || "",
              }}
            />
          )}
        </div>
      </div>
      {/* FRAME NEW */}
      {/* <News /> */}
    </Box>
  );
}

export default InterviewBody;
