import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import homeSlice from "./reducers/homeSlice";
import interviewSlice from "./reducers/interviewSlice";
import companyDetailSlice from "./reducers/companyDetailSlice";
import pressDetailSlice from "./reducers/pressDetailSlice";


const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({
  reducer: {
    homeSlice,
    interviewSlice,
    companyDetailSlice,
    pressDetailSlice
  },
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
