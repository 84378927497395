import { useMediaQuery } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPressList } from "../../../../reducers/pressDetailSlice";
import {
  detailpresssevices,
  detailservices,
} from "../../../../services/detailService";
import "./press.scss";

const Press = () => {
  const matchTablet_PC = useMediaQuery("(min-width: 1024px)");

  const pressData = useSelector((state) => state.companyDetailSlice.detailList);
  const pressId = pressData?.acf?.press;
  // const data = useSelector((state) => state.pressDetailSlice.pressList);
  const [loadmore, setLoadmore] = useState(true);

  // GET PRESS LIST
  async function getListPressFromAPI(id) {
    try {
      let ListPress = await detailpresssevices.getPressList(id);
      return ListPress;
    } catch (error) {
      console.log("Failed to fetch", error);
    }
  }

  const [dataPressList, setDataPressList] = useState([]);
  // console.log("dataPressList:", dataPressList);

  async function getListPressFromAPI(id) {
    try {
      let ListPress = await detailpresssevices.getPressList(id);
      return ListPress;
    } catch (error) {
      console.log("Failed to fetch", error);
    }
  }

  useEffect(() => {
    const listPress = [];
    const fetchData2 = async () => {
      // await Promise.all(
      //   pressId?.map(async (item) => {
      //     let getListPress = await getListPressFromAPI(item);
      //     console.log("getListPress:", getListPress);
      //     listPress.push(getListPress.data);
      //   })
      // );
      if (pressId) {
        for (const item of pressId) {
          let getListPress = await getListPressFromAPI(item);
          // console.log("getListPress:", getListPress);
          listPress.push(getListPress.data);
        }
        setDataPressList([...listPress]);
      }
    };

    fetchData2();
  }, [pressId]);

  const ToLoadMore = (e) => {
    setLoadmore((loadmore) => !loadmore);
  };

  return (
    <div id="detailpage-press">
      <div className="press">
        <div className="press-title" style={{ display: "flex" }}>
          <h2 className="press-text">Press</h2>
          <h2 className="dot">.</h2>
        </div>
        <hr />

        <div className="press-content">
          {matchTablet_PC
            ? dataPressList?.length > 5
              ? dataPressList
                  ?.filter((item2, index) =>
                    loadmore ? index < 5 : index >= 0
                  )
                  .map((item, i) => (
                    <div className="content">
                      <p className="category">{item?.acf?.category}</p>
                      <p className="question1">
                        <a
                          target="_blank"
                          href={item?.acf?.url}
                          className="hover:text-[#101010]"
                        >
                          {item?.acf?.title}
                        </a>
                      </p>
                      <p className="question2">{item?.acf?.preview_text}</p>
                      <hr />
                    </div>
                  ))
              : dataPressList.map((item, i) => (
                  <div className="content">
                    <p className="category">{item?.acf?.category}</p>
                    <p className="question1">
                      <a
                        target="_blank"
                        href={item?.acf?.url}
                        className="hover:text-[#101010]"
                      >
                        {item?.acf?.title}
                      </a>
                    </p>
                    <p className="question2">{item?.acf?.preview_text}</p>
                    <hr />
                  </div>
                ))
            : dataPressList?.length > 3
            ? dataPressList
                ?.filter((item2, index) => (loadmore ? index < 3 : index >= 0))
                .map((item, i) => (
                  <div className="content">
                    <p className="category">{item?.acf?.category}</p>
                    <p className="question1">
                      <a
                        target="_blank"
                        href={item?.acf?.url}
                        className="hover:text-[#101010]"
                      >
                        {item?.acf?.title}
                      </a>
                    </p>
                    <p className="question2">{item?.acf?.preview_text}</p>
                    <hr />
                  </div>
                ))
            : dataPressList?.map((item, i) => (
                <div className="content">
                  <p className="category">{item?.acf?.category}</p>
                  <p className="question1">
                    <a
                      target="_blank"
                      href={item?.acf?.url}
                      className="hover:text-[#101010]"
                    >
                      {item?.acf?.title}
                    </a>
                  </p>
                  <p className="question2">{item?.acf?.preview_text}</p>
                  <hr />
                </div>
              ))}
        </div>
        {matchTablet_PC && (
          <div>
            <button
              className="m-auto mt-[40px] sm:mt-[40px] xl:mt-[100px] text-[10px] sm:text-[14px] xl:text-[20px] underline opacity-80 hover:opacity-100 cursor-pointer"
              style={
                dataPressList?.length > 5
                  ? { display: "block", color: "black" }
                  : { display: "none" }
              }
              onClick={(e) => ToLoadMore(e)}
            >
              {loadmore ? <p>View more </p> : <p>Hide</p>}
            </button>
          </div>
        )}
        {!matchTablet_PC && (
          <div>
            <button
              className="m-auto mt-[40px] sm:mt-[40px] xl:mt-[100px] text-[10px] sm:text-[14px] xl:text-[20px] underline  opacity-80 hover:opacity-100 cursor-pointer"
              style={
                dataPressList?.length > 3
                  ? { display: "block", color: "black" }
                  : { display: "none" }
              }
              onClick={(e) => ToLoadMore(e)}
            >
              {loadmore ? <p>View more </p> : <p>Hide</p>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Press;
