import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { getDetailList } from "../../reducers/companyDetailSlice";
import FooterComponent from "../InterviewPage/component/Footer/Footer";
import Banner from "./Components/Banner/Banner";
import DetailHeader from "./Components/Header/DetailHeader";
import Investment from "./Components/Investment/Investment";
import Press from "./Components/Press/Press";
import "./detailpage.scss";

const DetailPage = () => {
  let { companyId } = useParams();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.companyDetailSlice.loading)
  // console.log('loading',isLoading)

  useEffect(() => {
    dispatch(getDetailList(companyId));
  }, []);

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);


  return (
  //   <>
  //  {isLoading ? <Loading/> :   <div>   
  //     <DetailHeader />
  //     <div id="detailpage-container">
  //       <div className="container">
  //         <Banner />
  //         <Investment />
  //         <Press />
  //       </div>
  //     </div>
  //     <FooterComponent />
  //   </div>} 
  //   </>
  <div>   
      <DetailHeader />
      <div id="detailpage-container">
        <div className="container">
          <Banner />
          <Investment />
          <Press />
        </div>
      </div>
      <FooterComponent />
    </div>
  
  );
};

export default DetailPage;
